<template>

  <b-row style="background-color: #F5F7FA; padding-bottom: 50px;" class="d-flex justify-content-center content">
    <b-col style="max-width: 1200px;">

    <the-modal v-show="deleteConfirmActive" style="position: absolute; width: 100%; z-index: 500;">

      <div @click="closeEverything" style="left: 0px; position: fixed; height: 100%; width: 100%;"></div>

      <div style="position: aboslute; max-width: 450px; margin-left: auto; margin-right: auto; z-index: 360; margin-top: 50px;">

        <div class="row">
          <div class="col-lg-12 bg-white text-center" style="padding-bottom: 30px; border-radius: 10px;">
            <h1 style="font-size: 25px; font-weight: 400; margin-top: 20px;">Are you sure?</h1>
            <p style="font-weight: 500; margin-top: -5px;">You are about to delete the category: <strong>{{ targetedCategory.display_name }}</strong></p>
            <b-button @click="deleteCategory(targetedCategory.category_id)" v-show="!processing" type="submit" class="btn btn-danger btn-dash-submit">Yes, Delete</b-button>
            <b-button v-show="processing" disabled type="submit" class="btn btn-primary btn-dash-submit"><b-spinner class="mr-2" small></b-spinner> Deleting...</b-button>
            <b-button @click="closeEverything" class="btn btn-light ml-3">Cancel</b-button>
          </div>
        </div>
      </div>
    </the-modal>

    <b-row>
      <b-col lg="12" class="mb-4">
        <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
          <b-card-body style="min-height: 150px;">
            <b-icon-plus @click="newCategory()" class="plus-icon"></b-icon-plus>
            <h1 style="font-size: 20px; margin-bottom: 25px; font-weight: 600;">Categories 
              <span style="background-color: rgb(23, 92, 142); margin-left: 10px; padding: 5px; border-radius: 20px; color: white; font-weight: 400; height: 25px; min-width: 25px; text-align: center; font-size: 14px;">{{ categoriesCount }}</span>
            </h1>
            <table class="table">
              <tbody>
                <tr class="small" v-for="(category, index) in categories" :key="category.category_id">
                  <td width="300" class="">{{ category.display_name }} 
                    <b-badge v-show="category.active == false" class="float-right" variant="secondary">Inactive</b-badge>
                  </td>
                  <td class="d-none d-md-table-cell">{{ category.description_1 }}</td>
                  <td width="80px;" class="">
                    <a @click.prevent="toggleDeleteConfirm(index)" class="float-right small p-1 btn-delete" href="#">Delete</a>
                  </td>
                  <td width="80px;" class="">
                    <router-link class="float-right small p-1 btn-dashboard" :to="{ name: 'Edit Category', params: { categoryId: category.category_id }}"><b-icon-pen></b-icon-pen> Edit</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-card-body>
        </b-card>
      </b-col>        
    </b-row>
  </b-col>
</b-row>

</template>

<script>
  
  import TheModal from '@/components/TheModal.vue'

  export default {

  name: 'categories',

  components: {
    TheModal,
  },

  metaInfo: {
    title: 'Categories'
  },

  created () {
    this.getCategories();
  },

  data() {
    return {
      categories: {},
      targetedCategory: {},
      processing: false,
    }
  },

  methods: {

    getCategories(){
      this.$store.commit('generateApiCreds');
      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'categories', {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          this.categories = resp.data;
        })
    },

    newCategory() {
      this.$router.push('/categories/new');
    },

    toggleDeleteConfirm(index) {
      window.scrollTo(0,0);
      this.updateTargetedCategory(index);
      this.$store.commit("toggleModal");
      this.$store.commit("toggleDeleteConfirm");
    },

    updateTargetedCategory(index) {
      this.targetedCategory = this.categories[index];
    },

    deleteCategory(category_id){
      this.processing = true;
      this.$store.commit("generateApiCreds");
      const self = this;

      let url = process.env.VUE_APP_API_PATH + "categories/" + category_id;

      this.$axios.delete(url, { 
        auth: {
          // this is to make sure our vue app is authorized
          username: this.$store.state.auth.apiUsername,
          password: this.$store.state.auth.apiPassword,
        },
        data: { 
          // this is to make sure our user is authorized
          user_id: this.$store.state.auth.userId,
          token: this.$store.state.auth.token,
        },
      })
      .then(function() {
        self.getCategories();
        self.processing = false;
        self.$store.commit("closeEverything");
      })
    },

    closeEverything() {
      this.$store.commit("closeEverything");
    }

  },

  computed: {

    categoriesCount: function() {
      return this.categories.length;
    },

    deleteConfirmActive() {
      return this.$store.state.deleteConfirmActive ? true : false;
    },

  },

};

</script>

<style scoped>

.plus-icon {
  height: 40px;
  width: 40px;
  background-color: rgb(245, 247, 250);
  color: #212529;
  border-radius: 25px;
  float: right;
}

.plus-icon:hover {
  background-color: rgb(23, 92, 142);
  cursor: pointer;
  color: white;
}

.widget-item {
  text-decoration: none;
}

.widget-item:hover {
  background-color: grey;
}

.table > tbody > tr:first-child > td {
    border: none;
}

.btn-dashboard {
  color: #6c757d;
  padding: 5px;
}

.btn-dashboard:hover {
  color: white;
  border-radius: 5px;
  background-color: rgb(23, 92, 142);
  text-decoration: none;
}

.content {
  padding-top: 20px;
}

@media screen and (max-width: 768px) {

  .content {
    margin-top: 0px;
    padding-top: 40px;
  }

}


</style>